exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutkamfung-js": () => import("./../../../src/pages/aboutkamfung.js" /* webpackChunkName: "component---src-pages-aboutkamfung-js" */),
  "component---src-pages-apply-js": () => import("./../../../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-buy-tab-js": () => import("./../../../src/pages/buy_tab.js" /* webpackChunkName: "component---src-pages-buy-tab-js" */),
  "component---src-pages-enquiry-js": () => import("./../../../src/pages/enquiry.js" /* webpackChunkName: "component---src-pages-enquiry-js" */),
  "component---src-pages-free-tab-js": () => import("./../../../src/pages/free_tab.js" /* webpackChunkName: "component---src-pages-free-tab-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-template-copy-5-js": () => import("./../../../src/pages/page_template copy 5.js" /* webpackChunkName: "component---src-pages-page-template-copy-5-js" */),
  "component---src-pages-page-template-js": () => import("./../../../src/pages/page_template.js" /* webpackChunkName: "component---src-pages-page-template-js" */),
  "component---src-pages-products-at-the-place-where-you-call-js": () => import("./../../../src/pages/products/at_the_place_where_you_call.js" /* webpackChunkName: "component---src-pages-products-at-the-place-where-you-call-js" */),
  "component---src-pages-products-digimon-js": () => import("./../../../src/pages/products/digimon.js" /* webpackChunkName: "component---src-pages-products-digimon-js" */),
  "component---src-pages-products-template-copy-js": () => import("./../../../src/pages/products/template copy.js" /* webpackChunkName: "component---src-pages-products-template-copy-js" */),
  "component---src-pages-products-template-js": () => import("./../../../src/pages/products/template.js" /* webpackChunkName: "component---src-pages-products-template-js" */),
  "component---src-pages-products-you-know-my-name-js": () => import("./../../../src/pages/products/you_know_my_name.js" /* webpackChunkName: "component---src-pages-products-you-know-my-name-js" */),
  "component---src-pages-student-demo-js": () => import("./../../../src/pages/student_demo.js" /* webpackChunkName: "component---src-pages-student-demo-js" */),
  "component---src-pages-theory-js": () => import("./../../../src/pages/theory.js" /* webpackChunkName: "component---src-pages-theory-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

